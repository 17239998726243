import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatGPT3.css';

const WhatGPT3 = () => (
  <div className="gpt3__whatgpt3 section__margin" id="wgpt3">
    <div className="gpt3__whatgpt3-feature">
      <Feature title="Service Offers" text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque reiciendis officia, 
      nisi veniam debitis dolorum ut beatae exercitationem voluptatibus, totam, odio at praesentium tempora! Neque, porro? 
      Recusandae consectetur laudantium excepturi illum distinctio explicabo dicta amet porro error harum, voluptate, molestiae 
      dignissimos dolores optio libero aliquid? Exercitationem fugiat hic nulla suscipit?" />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">Fixing Smiles Dental Offers:</h1>
      <p>Explore the Library</p>
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature title="Consultation" text="" />
      <Feature title="Oral Prophylaxis" text="" />
      <Feature title="Pit and Fissure Sealant" text="" />
      <Feature title="Composite Restoration" text="" />
      <Feature title="emporary Filling" text="" />
      <Feature title="Glass Ionomer" text="" />
      <Feature title="Oral Surgery" text="" />
      <Feature title="Implant" text="" />
      <Feature title="Bone Graft" text="" />
      <Feature title="Root Canal Treatment" text="" />
      <Feature title="Periodontal Treatment" text="" />
      <Feature title="Mouthguard" text="" />
      <Feature title="Whitenning" text=""/>
      <Feature title="Indirect Veneers" text="" />
      <Feature title="FPD" text="" />
      <Feature title="RPD" text="" />
      <Feature title="Complete Denture" text="" />
      <Feature title="Denture Repair" text="" />
      <Feature title="Reline/Rebase" text="" />
      <Feature title="Orthodontic Treatment" text="" />
      <Feature title="Retainers" text="" />
      <Feature title="Appliances" text="" />
      <Feature title="TMD Splint" text="" />
      <Feature title="TMD Splint" text="" />
    
    </div>
  </div>
);

export default WhatGPT3;