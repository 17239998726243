import React from 'react';
import people from '../../assets/people.png';
import ai from '../../assets/ai.png';
import './header.css';

const Header = () => (
  <div className="gpt3__header section__padding" id="home">
    <div className="gpt3__header-content">
      <h1 className="gradient__text">Fixing Smiles Dental Center</h1>
      <h1 className="gradient__text">General Dentistry</h1>
      <p>Melliza St. Poblacion Ilaud Zarraga, Iloilo City 5004</p>
      <p>Open: Monday - Sunday 10A.M. to 5:00P.M.</p>

      <div className="gpt3__header-content__input">
        <input type="email" placeholder="Your Email Address" />
        <button type="button">Get Started</button>
      </div>

      <div className="gpt3__header-content__people">
        <img src={people}  alt="people"/>
        <p>1,600 people requested access a visit in last 24 hours</p>
      </div>
    </div>

    <div className="gpt3__header-image">
      <img src={ai} alt="ai"/>
    </div>
  </div>
);

export default Header;