import React from 'react';
import gpt3Logo from '../../assets/logo.svg';
import './footer.css';

const Footer = () => (
  <div className="gpt3__footer section__padding">
    <div className="gpt3__footer-heading">
      <h1 className="gradient__text">Fixing Smiles Dental Center</h1>
      <h1 className="gradient__text">General Dentisty</h1>
    </div>

    <div className="gpt3__footer-btn">
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
    </div>

    <div className="gpt3__footer-links">
      <div className="gpt3__footer-links_logo">
        <img src={gpt3Logo} alt="gpt3_logo" />
        <p>Fixing Smiles Dental Center, <br /> All Rights Reserved</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Links</h4>
          <p><a href="#home">Home</a></p>
          <p><a href="#wgpt3">Dental Services</a></p>
          <p><a href="#possibility">Before and After</a></p>
          <p><a href="#features">Contact Us</a></p>
          <p><a href="#blog">Location</a></p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Company</h4>
        <p>Terms & Conditions </p>
        <p>Privacy Policy</p>
        <p>Contact</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Get in touch</h4>
        <p>For more info Please text or email us!</p>
        <p>09610058802 | 09275004759</p>
        <p>bagsainDMD@fixingsmilesdentalcenter.com</p>
      </div>
    </div>

    <div className="gpt3__footer-copyright">
      <p>@2023. All rights reserved.</p>
      <p>Design & Develop By: Francis</p>
    </div>
  </div>
);

export default Footer;